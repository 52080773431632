import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
	const [lang, setLang] = useState()
	useEffect(() => {
		setLang(sessionStorage.getItem('lang'))
	}, [])
	if (lang === 'ru') {
		return (
			<Layout>
				<SEO title="404: Not found" />
				<section className="w100 left contentFixed center-h">
					<div className="center-content w100 left">
						<div className="container">
							<i className="icon icon-serenay w100 left t-center fs60 lh60"></i>
							<h1 className="w100 left t-center fw6">OOOPS !</h1>
							<div className="w75 t-center fw5 center-w fs20">Я ДУМАЮ, ЧТО ЧТО-ТО ЕСТЬ</div>
							<div className="w50 t-center fw4 center-w fs18 pt25 col-xs-w90"><b className="w100 left t-center">Страница, которую вы ищете, не найдена.</b> Это чаще всего происходит, когда страница, на которую вы хотите пойти, недоступна. Если вы хотите, вы можете вернуться на домашнюю страницу, используя ссылки ниже, или вы можете выбрать между решениями и продуктами.декадентская страница-это то, что вам нужно.</div>
							<ul className="center-w pt35">
								<li className="wi left m5">
									<Link className="colored2-bg white p15 pt10 pb10 b-ra3 on bn bsn left soft" to="/ru">Главная</Link>
								</li>
								<li className="wi left m5">
									<Link className="colored2-bg white p15 pt10 pb10 b-ra3 on bn bsn left soft" to="/продукты">Продукты</Link>
								</li>
								<li className="wi left m5">
									<Link className="colored2-bg white p15 pt10 pb10 b-ra3 on bn bsn left soft" to="/решения">Решения</Link>
								</li>
							</ul>
						</div>
					</div>
				</section>
			</Layout>
		)
	} else if (lang === 'en') {
		return (
			<Layout>
				<SEO title="404: Not found" />
				<section className="w100 left contentFixed center-h">
					<div className="center-content w100 left">
						<div className="container">
							<i className="icon icon-serenay w100 left t-center fs60 lh60"></i>
							<h1 className="w100 left t-center fw6">OOOPS !</h1>
							<div className="w75 t-center fw5 center-w fs20">THERE IS SOMETHING THAT DON'T GO THERE I THINK</div>
							<div className="w50 t-center fw4 center-w fs18 pt25 col-xs-w90"><b className="w100 left t-center">The page you were looking for could not be found.</b> This mostly happens when the page you want to go to is not available. If you wish, you can return to the homepage using the links below or you can choose between solutions and products.</div>
							<ul className="center-w pt35">
								<li className="wi left m5">
									<Link className="colored2-bg white p15 pt10 pb10 b-ra3 on bn bsn left soft" to="/en">Home page</Link>
								</li>
								<li className="wi left m5">
									<Link className="colored2-bg white p15 pt10 pb10 b-ra3 on bn bsn left soft" to="/products">Products</Link>
								</li>
								<li className="wi left m5">
									<Link className="colored2-bg white p15 pt10 pb10 b-ra3 on bn bsn left soft" to="/solutions">Çözümler</Link>
								</li>
							</ul>
						</div>
					</div>
				</section>
			</Layout>
		)
	} else {
		return (
			<Layout>
				<SEO title="404: Not found" />
				<section className="w100 left contentFixed center-h">
					<div className="center-content w100 left">
						<div className="container">
							<i className="icon icon-serenay w100 left t-center fs60 lh60"></i>
							<h1 className="w100 left t-center fw6">OOOPS !</h1>
							<div className="w75 t-center fw5 center-w fs20">SANIRIM YOLUNDA GİTMEYEN BİRŞEYLER VAR</div>
							<div className="w50 t-center fw4 center-w fs18 pt25 col-xs-w90"><b className="w100 left t-center">Aradığınız sayfa bulunamadı.</b> Bu daha çok gitmek istdiğiniz sayfanın mevcut olmadığı zamanlarda meydana gelir. İsterseniz aşağıdaki bağlantıları kullanarak anasayfaya dönebilir yada çözümler ve ürünler arasında bir seçim yapabilirsiniz.</div>
							<ul className="center-w pt35">
								<li className="wi left m5">
									<Link className="colored2-bg white p15 pt10 pb10 b-ra3 on bn bsn left soft" to="/">Anasayfa</Link>
								</li>
								<li className="wi left m5">
									<Link className="colored2-bg white p15 pt10 pb10 b-ra3 on bn bsn left soft" to="/urunler">Ürünler</Link>
								</li>
								<li className="wi left m5">
									<Link className="colored2-bg white p15 pt10 pb10 b-ra3 on bn bsn left soft" to="/cozumler">Çözümler</Link>
								</li>
							</ul>
						</div>
					</div>
				</section>
			</Layout>
		)
	}
}

export default NotFoundPage
